import React from 'react'
import logo from '../../../images/logo.svg'
import profile from '../../../images/profile.svg'
import riseAppLockup from '../../SignUpSuccess/riseAppLockup.png'
import appStore from '../../SignUpSuccess/appStore.png'
import { sendAnalytics } from '../../../utils/webOnboarding2/webOnboardingAnalytics'
import StripeConfirmationBackground from '../images/StripeConfirmationBackground.svg'
import Title from '../components/Title'

export function StripeConfirmationView(props: { email: string; reminderDate: string | null }) {
  return (
    <div className="mx-auto w-px-375 sm:w-px-640 relative z-10">
      <div className={'py-6 md:py-8'}>
        <img src={logo} alt="RISE" className="pl-3" style={{ height: 16 }} />
      </div>
      <div
        className={`w-px-375 md:w-px-640 w-full h-full pb-6 ${
          window.innerHeight < 800 ? 'space-y-20' : 'space-y-40'
        } sm:space-y-0 sm:pt-20`}
        style={{
          backgroundImage: `url(${StripeConfirmationBackground})`,
          backgroundSize: `cover`,
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="pt-32 flex flex-col">
          <text className="text-white pt-8 pb-2 text-2xl text-center px-4">Enjoy your free trial!</text>
          {props.reminderDate ? (
            <text className={`text-white text-center font-bold px-12`}>
              Your annual plan will be charged {props.reminderDate ?? '...'}.
            </text>
          ) : null}
          <div className="flex justify-end my-3 pb-2">
            <hr className="w-full border-t border-white opacity-25" />
          </div>
          {props.email ? (
            <div
              className={`px-3 opacity-0 text-white text-lg font-medium flex items-center py-1 transition-opacity duration-500 ease-in-out' ${
                props.email && 'opacity-100'
              }`}
            >
              <img src={profile} alt="" className="inline mr-2 w-5" />
              {props.email ?? '...'}
            </div>
          ) : null}
          <div className={`px-3 pt-1 pb-4`}>
            <text className="text-white text-sm ">
              Now, just download the RISE app and use this email address to log in and start your 7 days free.
            </text>{' '}
          </div>

          <div className="flex justify-end my-2 pb-2">
            <hr className="w-full border-t border-white opacity-25" />
          </div>

          <div className={`pb-3`}>
            <Title
              title={'Trial Reminder'}
              subtitle={
                'We’ll send you an email reminder two days before your free trial expires and paid subscription ($59/year) starts.'
              }
            />
          </div>
          <div className="flex justify-end my-2 pb-2 ">
            <hr className="w-full border-t border-white opacity-25" />
          </div>

          <div className={`px-3`}>
            <img
              src={riseAppLockup}
              alt="RISE: Sleep & Energy Tracker - Better Health, Energy & Focus"
              className="self-start mb-6"
              style={{ maxHeight: 83.5 }}
            />
            <div className="flex" onClick={() => sendAnalytics('App Store redirect clicked')}>
              <a href="https://apps.apple.com/us/app/rise-sleep-energy-tracker/id1453884781" className="block mr-4">
                <img src={appStore} alt="Download on the App Store" className="self-start" style={{ height: 50 }} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
