import React, { useState } from 'react'
import '../../screens/StripeCustomCheckout/Checkout.css'
import riseLogo from './assets/app_icon3x.png'
import iconMorning from './assets/icon-morning.png'
import iconWaves from './assets/icon_SR_sm.png'
import melatoninIcon from './assets/melatonin_icon.png'
import iconClock from './assets/icon_clock.png'
import lockIcon from './assets/lock_icon.png'
import timeline from './assets/Union.png'
import bellIcon from './assets/bell_icon.png'
import smileyIcon from './assets/smiley_icon.png'
import { GoldText, SatisfactionModal } from '../components'
import { Offer } from '../../utils/getDiscountOffer'

import './CheckoutInformation.css'

import {
  Experiment,
  Variant,
  emitter,
  // @ts-ignore
} from '@marvelapp/react-ab-test'

const paywallV3HeaderExperiment = 'Paywall V3 - Header'
const originalVariant = 'Benefit Shown'
const customVariant = 'Discount Shown'

emitter.defineVariants(paywallV3HeaderExperiment, [originalVariant, customVariant], [100, 0])

interface HeaderProps {
  offer?: Offer | null
}

const OriginalHeader = ({ offer }: HeaderProps) => {
  const subTitle = () => {
    if (offer?.discountPercentage && offer?.discountPercentage !== '0%') {
      if (offer?.daysFree === 0) {
        return `Redeem your ${offer.discountPercentage} discount.`
      }
      return `Try free for ${offer?.daysFree ? offer.daysFree : 7} days, then ${offer.discountPercentage} off.`
    }
    if (offer?.fixedDiscount) {
      return `Try free for ${offer?.daysFree ? offer.daysFree : 7} days, then ${offer.fixedDiscount} off.`
    }

    if (offer?.daysFree === 0) {
      return ''
    }

    return `Try free for ${offer?.daysFree ? offer.daysFree : 7} days.`
  }

  return (
    <>
      <p className="text-white take-control">
        Take control of your
        <br />
        sleep & energy.
      </p>
      <p className="subtitle">{offer ? subTitle() : ''}</p>
    </>
  )
}

const ExperimentalHeader = ({ offer }: HeaderProps) => {
  const title = () => {
    if (offer?.discountPercentage && offer?.discountPercentage !== '0%') {
      return `Get ${offer.discountPercentage} off`
    }
    if (offer?.fixedDiscount) {
      return `Get ${offer.fixedDiscount} off`
    }
    return `Try FREE for ${offer?.daysFree ? offer.daysFree : 7} days`
  }

  return (
    <>
      <p className="text-white take-control">{title()}</p>
      <p className="subtitle subtitleExperiment">Take control of your sleep & energy.</p>
    </>
  )
}

const SurveyHeader = ({ offer }: HeaderProps) => {
  const subTitle = () => {
    if (offer?.discountPercentage && offer?.discountPercentage !== '0%') {
      return `So we'd like to offer you a ${offer?.daysFree ? offer.daysFree : 7}-day free trial, and a ${
        offer.discountPercentage
      } discount`
    }
    return `So we'd like to offer you a ${offer?.daysFree ? offer.daysFree : 7}-day free trial`
  }

  return (
    <>
      <p className="subtitle titleSurvey">
        We understand that committing to a new subscription or app could be difficult. Especially when it’s something
        you haven’t tried yet.
      </p>
      <p className="subtitle subtitleSurvey">{subTitle()}</p>
    </>
  )
}

const ParentHeader = ({ offer }: HeaderProps) => {
  return (
    <>
      <p className="text-white take-control titleParent">
        Give <GoldText>RISE</GoldText> to your teen
      </p>
      <p className="subtitle">{offer?.daysFree ? offer.daysFree : 7} days free</p>
    </>
  )
}

const reminderDateFromChargeDate = (days: number) => {
  const someDate = new Date()
  someDate.setDate(someDate.getDate() + days)
  return someDate.toLocaleDateString('en-us', {
    day: 'numeric',
    month: 'short',
  })
}

type WebOnboardingV3CheckoutInformationProps = {
  token?: string | null
  offer: Offer | undefined
  chargeDate?: string | null
  survey?: boolean
  parent?: boolean
}

const WebOnboardingV3CheckoutInformation: React.FC<WebOnboardingV3CheckoutInformationProps> = ({
  token,
  offer,
  chargeDate,
  survey,
  parent,
}) => {
  const [showModal, setShowModal] = useState(false)

  const closeModal = () => {
    setShowModal(false)
  }

  // the methods below will be useful if this page uses codes dynamically in the future
  const isWinback = () => {
    const params = new URLSearchParams(window.location.search)
    return Boolean(params.get('winback'))
  }

  const monthlyPriceDisplay = () => {
    if (offer?.billingInterval === 'month') {
      return ''
    }
    if (offer?.perMonth) {
      return `(${offer.perMonth}/mo)`
    }
    return '($4.99/mo)'
  }

  const billingIntervalDisplay = () => {
    if (offer?.billingInterval) {
      return offer.billingInterval.toUpperCase() + 'LY'
    }
    return 'YEARLY'
  }

  const lineThroughPrice = () => {
    if (token && offer) {
      if (offer.originalPrice) {
        return offer.originalPrice
      }
      return offer.billingInterval === 'year' ? '$69.99' : '$9.99'
    }
    if (offer) {
      if (offer.originalPrice) {
        return offer.originalPrice
      }
      if (offer.discountPercentage) {
        return offer.billingInterval === 'year' ? '$69.99' : '$9.99'
      }
      return offer.billingInterval === 'year' ? '$89.99' : '$12.99'
    }
    return '$89.99'
  }

  const showLineThrough = () => {
    const originalPrice = lineThroughPrice()
    if (offer) {
      return originalPrice !== offer.price
    }
    return true
  }

  // const offerPriceDisplay = () => {
  //   if (offer?.price) {
  //     return offer.price
  //   }
  //   return '$59.99'
  // }

  // const chargeDateDisplay = () => {
  //   if (chargeDate) {
  //     return new Date(chargeDate).toLocaleDateString()
  //   }
  //   return ''
  // }

  const monthlyPrice = () => {
    if (offer?.billingInterval === 'month') {
      return offer.price
    }

    if (offer?.price) {
      const price = (parseFloat(offer.price.substring(1)) / 12).toFixed(3)
      return `$${price.toString().slice(0, -1)}`
    }
  }

  return (
    <>
      {showModal ? <SatisfactionModal onClose={closeModal} /> : null}
      <div className="pl-10 pr-10">
        <img src={riseLogo} alt="" className={'riseLogo'} />
        <Experiment name={paywallV3HeaderExperiment}>
          <Variant name={originalVariant}>
            {survey ? (
              <SurveyHeader offer={offer} />
            ) : parent ? (
              <ParentHeader offer={offer} />
            ) : (
              <OriginalHeader offer={offer} />
            )}
          </Variant>
          <Variant name={customVariant}>
            <ExperimentalHeader offer={offer} />
          </Variant>
        </Experiment>
      </div>
      {!survey ? (
        <div className="pl-5 pr-5">
          <div>
            <p className={'text-white with-rise'}>With RISE, you {parent ? 'help your teen' : 'will'}</p>
          </div>

          <ul className={`list-none mt-3 text-xs text-white sales-item ${offer?.daysFree === 0 ? 'pb-2' : 'pb-6'}`}>
            <li key={1} className="flex font-medium items-center pb-5">
              <img src={iconMorning} alt="" className="inline" style={{ height: 30, marginRight: 10 }} />
              Wake up more energized and refreshed
            </li>
            <li key={2} className="flex font-medium items-center pb-5">
              <img src={iconWaves} alt="" className="inline" style={{ height: 30, marginRight: 10 }} />
              Learn to manage {parent ? 'their' : 'your'} daily dips and peaks to maximize {parent ? 'their' : 'your'}{' '}
              energy
            </li>
            <li key={3} className="flex font-medium items-center pb-5">
              <img src={melatoninIcon} alt="" className="inline" style={{ height: 30, marginRight: 10 }} />
              Develop healthier sleep habits
            </li>
            <li key={4} className="flex font-medium items-center pb-5">
              <img src={iconClock} alt="" className="inline" style={{ height: 30, marginRight: 10 }} />
              Receive notifications to stay on goal
            </li>
          </ul>
        </div>
      ) : null}

      {offer?.daysFree !== 0 ? (
        <div className="pl-5 pr-5" style={{ backgroundColor: '#16161A' }}>
          <div className={'text-white'} style={{ position: 'relative' }}>
            <p style={{ fontSize: '21px', marginTop: '1.8em', fontWeight: 700 }}>
              {parent
                ? 'Unlock RISE with a Free Trial'
                : `Start your ${offer?.daysFree ? offer.daysFree : 7} Day Trial Today`}
            </p>
            <img className={'timeline'} src={timeline} alt={''} />

            <ul className="list-none mt-3 text-xs text-white">
              <li key={1} className="flex font-medium items-start pb-4">
                <img src={lockIcon} alt="" className="inline" style={{ height: 18, marginRight: 10, zIndex: 1 }} />
                <div>
                  <p className={'list-header'}>Today</p>
                  <p className={'list-info'}>
                    Unlock full access to {parent ? 'RISE features for your teen' : 'all RISE features'}.
                  </p>
                </div>
              </li>
              <li key={2} className="flex font-medium items-start pb-4" style={{ marginTop: '20px' }}>
                <img src={bellIcon} alt="" className="inline" style={{ height: 18, marginRight: 10, zIndex: 1 }} />
                <div>
                  <p className={'list-header'}>
                    {offer?.daysFree ? reminderDateFromChargeDate(offer?.daysFree - 2) : 7}
                  </p>
                  <p className={'list-info'}>
                    We'll email {parent ? 'your teen' : 'you'} a reminder 2 days
                    <br /> before your Free Trial ends.
                  </p>
                </div>
              </li>
              <li key={3} className="flex font-medium items-start pb-3" style={{ marginTop: '20px' }}>
                <img src={smileyIcon} alt="" className="inline" style={{ height: 18, marginRight: 10, zIndex: 1 }} />
                <div>
                  <p className={'list-header'}>{offer?.daysFree ? reminderDateFromChargeDate(offer?.daysFree) : 7}</p>
                  <p className={'list-info'}>
                    Free Trial ends. Your card will be
                    <br />
                    automatically charged for your
                    <br />
                    {offer?.billingInterval === 'month' ? `first month.` : `1-year subscription.`}
                  </p>
                </div>
              </li>
            </ul>

            <p className={'five-days'}>
              Most RISE users feel a difference
              <br />
              in just 5 days.
            </p>
          </div>
        </div>
      ) : null}

      <div
        className={`${offer?.daysFree === 0 ? 'pt-0' : 'pt-8'} text-white pl-5 pr-5 pb-5`}
        style={{ visibility: offer ? 'visible' : 'hidden' }}
      >
        <p>
          {!isWinback() ? (
            offer?.daysFree === 0 ? (
              <div style={{ lineHeight: '21px' }}>
                <p style={{ fontSize: '1.4em', fontWeight: 400, color: 'white' }}>
                  {showLineThrough() ? <span className="line-through">{lineThroughPrice()}</span> : ''} {offer?.price}
                  <span style={{ fontSize: '0.7em', fontWeight: 200 }}>/YEAR</span>
                </p>
                <p style={{ fontSize: '0.9em', fontWeight: 400, color: 'white', paddingTop: '0.3em' }}>
                  THAT'S JUST <span style={{ fontSize: '1.3em', fontWeight: 200 }}>{monthlyPrice()}</span> PER MONTH
                </p>
              </div>
            ) : (
              <div style={{ lineHeight: '21px' }}>
                <span
                  style={{
                    verticalAlign: 'super',
                    position: 'relative',
                    top: '-0.3em',
                    fontSize: '90%',
                  }}
                >
                  $
                </span>
                <span style={{ fontSize: '2em', fontWeight: 500 }}>0</span> DUE TODAY, THEN {monthlyPrice()} PER MONTH
                <br />
                (BILLED {billingIntervalDisplay()} AT{' '}
                {showLineThrough() ? <span className="line-through">{lineThroughPrice()}</span> : ''} {offer?.price})
              </div>
            )
          ) : (
            <>
              <span style={{ fontSize: '2em' }}>$0</span> DUE TODAY, THEN {offer?.discountPercentage} OFF AT{' '}
              {offer?.price} BILLED {billingIntervalDisplay()} {monthlyPriceDisplay()}
            </>
          )}
        </p>
        {offer?.daysFree !== 0 ? (
          <p style={{ fontSize: '14px', color: '#BDBDBD', paddingTop: '8px' }}>
            You won't be charged until after your free trial.
          </p>
        ) : null}
        <p style={{ color: '#8E58FF', fontWeight: 600, paddingTop: '8px' }} onClick={() => setShowModal(true)}>
          See our Satisfaction Guarantee
        </p>
      </div>
    </>
  )
}

export default WebOnboardingV3CheckoutInformation
