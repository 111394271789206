import { DynamicCodeProps } from '../../utils/dynamicCodes/types'
import { PaymentRequest, loadStripe } from '@stripe/stripe-js'
import { BASE_URL, useQueryToken } from '../../utils/dynamicCodes/hooks'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import PaymentIntentScreen from '../../screens/StripeCustomCheckout/PaymentIntentScreen'
import MaybeStagingBadge from '../../components/MaybeStagingBadge'
import Environment from '../../utils/Environment'
import Analytics from '../../utils/Analytics'

import * as Sentry from '@sentry/react'
import { UserAlreadySubscribedMessage, bypassCheckoutForDuplicateCustomer } from '../../utils/dynamicCodes/redirects'
import stripeLogo from '../../images/stripe.png'
import WebOnboardingV3CheckoutInformation from './WebOnboardingV3CheckoutInformation'
import riseLogo from '../assets/rise-logo.png'

import './CheckoutInformation.css'
import logos from '../../screens/DynamicOfferScreen/recommended-logos.png'
import { SatisfactionModal } from '../components'
import { useHistory } from 'react-router-dom'
import { Offer } from '../../utils/getDiscountOffer'
import shareImage from './assets/share_image.png'
import { Helmet } from 'react-helmet'

const key = Environment.getVar('REACT_APP_STRIPE_KEY')
const stripePromise = loadStripe(key)

export const stripeStyle = {
  colorPrimary: '#0570de',
  colorBackground: '#30313d',
  colorText: 'white',
  colorDanger: '#df1b41',
  borderRadius: '4px',
  colorPrimaryText: 'white',
  fontSizeSm: '1rem',
}

interface PaywallProps extends DynamicCodeProps {
  offer?: Offer
}

const WebOnboardingV3Paywall = ({ match, offer }: PaywallProps) => {
  const [options, setOptions] = useState<object>({})
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | undefined>(undefined)
  const [clientSecret, setClientSecret] = useState<string>('')
  const [subscriptionError, setSubscriptionError] = useState<boolean>(false)
  const [chargeDate, setChargeDate] = useState<string>('')

  const token = useQueryToken()
  const code = match.params.name

  const history = useHistory()

  useDocumentTitle('Rise Thanks You')

  useEffect(() => {
    Analytics.track(`Displaying experiment Web Paywall Aug 2022 variant V3`)
    if (offer) {
      setupStripe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer])

  const isWinback = () => {
    const params = new URLSearchParams(window.location.search)
    return Boolean(params.get('winback'))
  }

  const isFromSurvey = () => {
    const params = new URLSearchParams(window.location.search)
    return Boolean(params.get('survey'))
  }

  const isParent = () => {
    const params = new URLSearchParams(window.location.search)
    return Boolean(params.get('parent'))
  }

  const setupStripe = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/api/stripe-setup-checkout`, {
        token,
        env: Environment.getVar('REACT_APP_ENV') ?? 'test',
        winback: isWinback(),
      })
      const { client_secret, person_id, charge_date } = response.data

      if (person_id) {
        Analytics.identify(person_id)
      }

      if (charge_date) {
        setChargeDate(charge_date)
      }

      Analytics.track('Custom Stripe Checkout Page Shown', { code })
      setClientSecret(client_secret)

      const key = Environment.getVar('REACT_APP_STRIPE_KEY')
      if (!key) {
        throw new Error('Stripe key must be defined')
      }
      const stripe = await loadStripe(key)

      if (stripe && offer) {
        const labelText = !isWinback()
          ? offer.daysFree === 0
            ? 'Rise Science'
            : `Rise Science - FREE for ${offer.daysFree} days, then ${offer.price} after trial`
          : `Rise Science - Only charged when your current subscription expires, then ${offer.price}`

        let amount = 0
        if (offer.daysFree === 0) {
          if (offer.price_as_float && (offer.discountPercentage !== '0%' || offer.fixedDiscount !== null)) {
            amount = Number(offer.price_as_float.toFixed(2)) * 100
            amount = Math.ceil(amount)
          } else {
            amount = 6999
          }
        }

        const paymentRequest = stripe.paymentRequest({
          country: 'US',
          currency: 'usd',
          total: {
            label: labelText,
            amount: amount,
          },
          requestPayerEmail: true, // TODO: think about doing this dynamically??
          requestPayerName: true,
        })
        const canUseAppleOrGooglePay = await paymentRequest.canMakePayment()
        if (canUseAppleOrGooglePay) {
          const { applePay, googlePay } = canUseAppleOrGooglePay
          if (applePay) {
            Analytics.track('Apple Pay Shown', { code })
          }
          if (googlePay) {
            Analytics.track('Google Pay Shown', { code })
          }
          // @ts-ignore
          setPaymentRequest(paymentRequest)
        } else {
          Analytics.track('Credit Card Shown', { code })
        }
      }

      const options = {
        clientSecret: client_secret,
        appearance: {
          variables: {
            ...stripeStyle,
          },
        },
      }
      setOptions(options)
    } catch (error) {
      if (error.response?.data === UserAlreadySubscribedMessage) {
        setSubscriptionError(true)
        bypassCheckoutForDuplicateCustomer(code, token)
      } else {
        Sentry.captureException(new Error(error))
        Analytics.track('Unable to set up Stripe Custom Checkout Page', {
          code,
          env: Environment.getVar('REACT_APP_ENV') ?? 'test',
          email: token,
        })
      }
    }
  }
  // Use session storage to determine whether or not the modal has should be shown
  let modalViewed = false

  // @ts-ignore
  if (history?.location?.state?.from === 'onboarding') {
    // only show the modal if the user is coming from our V3 Onboarding experience
    try {
      modalViewed = Boolean(JSON.parse(window.sessionStorage.getItem('satisfaction-modal-viewed') ?? 'false'))
    } catch {}
  } else {
    modalViewed = true
  }
  const [showModal, setShowModal] = useState(!modalViewed)

  const closeModal = () => {
    window.sessionStorage.setItem('satisfaction-modal-viewed', 'true')
    setShowModal(false)
  }

  return (
    <div className={`w-full h-screen mx-auto hills-background`}>
      <Helmet>
        <meta id="og-image" property="og:image" content={`${window.location.origin.toString()}${shareImage}`} />
      </Helmet>
      {showModal ? <SatisfactionModal onClose={closeModal} /> : null}
      <nav className="ob3__layout-grid__nav_not_fixed">
        <div className="ob3__layout-grid__nav__group">
          <div className="ob3__layout-grid__nav__items_taller">
            <p className="ob3__layout-grid__nav__brand">
              <img alt="RISE logo" src={riseLogo} width="56" />
            </p>
          </div>
        </div>
      </nav>
      <div className={`mx-auto sm:w-px-640 md:w-px-640 relative z-10`}>
        <MaybeStagingBadge />
        <div
          style={{ minHeight: window.innerHeight }}
          className={`h-screen w-full ${window.innerHeight < 800 ? 'space-y-20' : 'space-y-40'} sm:space-y-0 sm:pt-410`}
        >
          <div className="pt-2 flex flex-col">
            <WebOnboardingV3CheckoutInformation
              token={token}
              offer={offer}
              survey={isFromSurvey()}
              parent={isParent()}
              chargeDate={chargeDate}
            />

            <div>
              {subscriptionError ? (
                <div className={'text-white'}>Your subscription is active!</div>
              ) : Object.keys(options).length ? (
                <>
                  <div className={'pl-5 pr-5'}>
                    <Elements stripe={stripePromise} options={options}>
                      <PaymentIntentScreen
                        code={code}
                        paymentRequest={paymentRequest}
                        clientSecret={clientSecret}
                        chargeDate={chargeDate}
                        daysFree={offer?.daysFree}
                      />
                    </Elements>
                  </div>
                  <div>
                    <p
                      className="text-white z-10"
                      style={{
                        paddingLeft: '22px',
                        paddingTop: '30px',
                        paddingBottom: '5px',
                        fontWeight: 400,
                        fontSize: '12px',
                      }}
                    >
                      RECOMMENDED BY
                    </p>
                    <div style={{ opacity: 0.1, border: '1px solid #FFFFFF' }} />
                    <div className="pt-0 pb-0 z-10" style={{ textAlign: 'center' }}>
                      <img src={logos} alt="" className="inline logos" />
                    </div>
                    <div style={{ opacity: 0.1, border: '1px solid #FFFFFF' }} />
                    <img
                      src={stripeLogo}
                      style={{
                        width: 190,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: '2.5rem',
                        marginTop: '16px',
                      }}
                      alt="Powered by Stripe"
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WebOnboardingV3Paywall
