export interface Offer {
  discountPercentage: string
  originalPrice: string
  price: string
  coupon?: string
  header?: string
  offerType?: string // maybe should only allow EXTENDED_FREE_TRIAL or DISCOUNTED_PRICE
  daysFree?: number
  fixedDiscount?: string
  billingInterval?: string
  whitelistStatus?: boolean
  perMonth?: string
  price_as_float?: number
}
const offers: { [key: string]: Offer } = {
  new_member_nov_2020: {
    discountPercentage: '40%',
    originalPrice: '$59.99',
    price: '$35.99',
    coupon: 'NEWMEMBER', // This is a generic one that works out of the gate for 7-days
  },
  black_friday_2020: {
    discountPercentage: '50%',
    originalPrice: '$59.99',
    price: '$29.99',
    coupon: 'BLACKFRIDAY2020',
  },
  new_years_2021: {
    discountPercentage: '50%',
    originalPrice: '$59.99',
    price: '$29.99',
    coupon: 'SLEEP2021',
  },
  sleep_day_2021: {
    discountPercentage: '50%',
    originalPrice: '$59.99',
    price: '$29.99',
    coupon: 'SLEEP2021',
  },
  renewal_2021: {
    discountPercentage: '50%',
    originalPrice: '$59.99',
    price: '$29.99',
    coupon: 'Renewal2021',
  },
  RISE: {
    // default
    discountPercentage: '0%',
    originalPrice: '$59.99',
    price: '$59.99',
    coupon: 'RISE',
    header: 'Get 1 week free!',
  },
  jobble: {
    discountPercentage: '0%',
    originalPrice: '$59.99',
    price: '$59.99',
    coupon: 'JOBBLE',
    header: 'Get 60-days free!',
    offerType: 'EXTENDED_FREE_TRIAL',
  },
  fb: {
    discountPercentage: '0%',
    originalPrice: '$59.99',
    price: '$59.99',
    coupon: 'FB',
    header: 'Get 1-week free!',
    offerType: 'EXTENDED_FREE_TRIAL',
  },
  facebook: {
    // Note this is for Facebook employees via Fond, not for facebook the marketing channel
    discountPercentage: '40%',
    originalPrice: '$59.99',
    price: '$35.99',
    coupon: 'FACEBOOK',
    header: 'Get 1-week free!',
    offerType: 'EXTENDED_FREE_TRIAL',
  },
  perkspot: {
    discountPercentage: '0%',
    originalPrice: '$59.99',
    price: '$59.99',
    coupon: 'PERKSPOT',
    header: 'Get 30-days free!',
  },
  leon: {
    discountPercentage: '40%',
    originalPrice: '$59.99',
    price: '$35.99',
    coupon: 'LEON',
    header: 'Get 30-days free!',
    offerType: 'DISCOUNTED_PRICE',
  },
  gift: {
    discountPercentage: '0%',
    originalPrice: '$59.99',
    price: '$59.99',
    coupon: 'gift',
    header: 'Get 30-days free!',
  },
  survey: {
    discountPercentage: '0%',
    originalPrice: '$59.99',
    price: '$59.99',
    coupon: 'survey',
    header: 'Get 30-days free!',
  },
} as const
const getDiscountOffer = (offer: keyof typeof offers) => offers[offer]
export default getDiscountOffer
