import React, { useEffect } from 'react'
import MaybeStagingBadge from '../../components/MaybeStagingBadge'
import logo from '../../images/logo.svg'
import appStore from '../SignUpSuccess/appStore.png'
import riseAppLockup from '../SignUpSuccess/riseAppLockup.png'
import Analytics from '../../utils/Analytics'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import partnerConnect from './partner-connect.png'

interface AppStoreLinkExperiment {
  link: string
  variantName: string
}

const control: AppStoreLinkExperiment = {
  link: 'https://apps.apple.com/us/app/rise-sleep-energy-tracker/id1453884781?ppid=66df1d40-0308-417f-a459-165db278fcd3',
  variantName: 'control',
}

const partnerConnectOptimized: AppStoreLinkExperiment = {
  link: 'https://apps.apple.com/us/app/rise-sleep-energy-tracker/id1453884781?ppid=267493b2-daa1-4bf7-8281-a15d46b96e3c',
  variantName: 'partner_page',
}

const getRandomAppStoreVariant = (): AppStoreLinkExperiment => {
  const zeroOrOne = Math.floor(Math.random() * 2)

  if (zeroOrOne === 0) {
    return partnerConnectOptimized
  }
  return control
}

const PartnerConnectPage = () => {
  const l = useLocation()
  const params = new URLSearchParams(l.search)
  const code = params.get('code')
  const deepLink = `nyx://partner-connect?code=${code}`

  const appStoreVariant = getRandomAppStoreVariant()

  const fireAppStoreClickedAnalytics = () => {
    Analytics.track('Store Redirect Clicked', {
      store: 'appStore',
      variant: appStoreVariant.variantName,
      origin: 'partner connect invite webpage',
    })
  }

  useEffect(() => {
    Analytics.track('Partner Connect Page Viewed', { code })
  })

  return (
    <div className="w-full h-screen mx-auto" id="new-years-2021">
      <Helmet>
        <meta id="og-image" property="og:image" content={`${window.location.origin.toString()}${partnerConnect}`} />
      </Helmet>
      <div className="mx-auto w-px-320 sm:w-px-640  relative z-10">
        <MaybeStagingBadge />
        <div className={'py-6 md:py-12'}>
          <img src={logo} alt="RISE" className="self-start" style={{ height: 16 }} />
        </div>
        <div
          className={`w-px-320 md:w-px-600 ${
            window.innerHeight < 800 ? 'space-y-20' : 'space-y-40'
          } sm:space-y-0 sm:pt-20`}
        >
          <div className="pt-8 flex flex-col">
            <h1 className="text-white pt-2 pb-3 text-4xl font-bold leading-tight">Connect with your partner on RISE</h1>
            <div className="flex justify-end my-2  pb-2">
              <hr className="w-full border-t border-white opacity-25" />
            </div>
            <p className="text-white text-sm">
              <strong>I don't have the RISE app</strong>.
            </p>
            <ol className="text-white text-sm pt-2">
              <li>
                1. Download the RISE app from the{' '}
                <a href={appStoreVariant.link} onClick={fireAppStoreClickedAnalytics} className="underline">
                  App Store
                </a>
                . Link below.
              </li>
              <li>2. Create your account in the app.</li>
              <li>3. Once onboarding is complete, click on the link your partner sent to accept their invite.</li>
            </ol>
            <br />
            <p className="text-white text-sm">
              <strong>I have the RISE app.</strong>
            </p>
            <ol className="text-white text-sm pt-2">
              <li>1. Make sure you have the latest version.</li>
              <li>
                2. Click{' '}
                <a
                  href={deepLink}
                  className="underline"
                  onClick={() => Analytics.track('Partner Connect Deep Link Clicked', { code })}
                >
                  this link
                </a>{' '}
                to accept invite.
              </li>
            </ol>
            <div className="flex justify-end my-8 pb-2 ">
              <hr className="w-full border-t border-white opacity-25" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex">
            <a href={appStoreVariant.link} onClick={fireAppStoreClickedAnalytics} className="block mr-4">
              <img
                src={riseAppLockup}
                alt="RISE: Sleep & Energy Tracker - Better Health, Energy & Focus"
                className="self-start mb-6"
                style={{ maxHeight: 83.5 }}
              />
              <img src={appStore} alt="Download on the App Store" className="self-start" style={{ height: 50 }} />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PartnerConnectPage
